import { PagamentoRetorno } from './../../../models/pagamentoRetorno';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { zip } from 'rxjs';
import { CompanySubscription } from 'src/app/models/company-subscription';
import { Plan } from 'src/app/models/plan';
import { UserCallback } from 'src/app/models/user-callback';
import { CompanyService } from 'src/app/service/company.service';
import { PlanService } from 'src/app/service/plan.service';
import { UserService } from 'src/app/service/user.service';
import { FormateDate } from 'src/app/utils/formateDate';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { StepsUtil } from 'src/app/utils/steps';
import { Spinner } from 'src/app/utils/stpiner';
import { SubscriptionPurchase } from 'src/app/models/subscription-purchase';
import { StorageUtil } from 'src/app/utils/storage';
import { SubscriptionBarcodeInterface } from 'src/app/models/subscription-barcode';
import { CompanySubscriptionPurchase } from 'src/app/models/companySubscription-purchase';

@Component({
  selector: 'app-pagamento-regra',
  templateUrl: './pagamento-regra.component.html',
  styleUrls: ['./pagamento-regra.component.css']
})
export class PagamentoRegraComponent implements OnInit {

  data: UserCallback;
  plano: Plan;

  tipoPessoa: "PJ" | "PF";
  subsId: string;
  companySubscription: CompanySubscription;

  islinkExt: boolean = false;
  social: string = '';

  habilitaComponenteCartao = false;


  //Dados Componente Pagamento
  cartaoQuantidadeParcelas: number = 6;
  cartaoValor: number;
  boletoValor: number;
  boletoVencimento: string;

  //Componente Cartão
  habilitaComponenteCartaoToken = false; //Habilita Componente Cartão Token
  qtdParcelas;
  cartaoNumero;
  cartaoNome;
  cartaoValidade;
  cartaoCodigo;
  cartaoToken;

  constructor(
    private planService: PlanService,
    private route: ActivatedRoute,
    private userService: UserService,
    private companyService: CompanyService,
    private ngZone: NgZone,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
      Spinner.spinner(document, true);
      if (params['uId'] && params['pId']) {
        this.loadExternalPf(params['uId'], params['pId']);
      } else if (params['sId'] && params['pId']) {
        this.loadExternalPj(params['sId'], params['pId']);
      } else {
        this.loadData();
        Spinner.spinner(document);
      }

      if (params['social']) {
        this.social = params['social'];
      }

    });
  }

  ngOnInit() {
  }


  /***************** REGRAS DE CARREGAMENTO DOS VALORES DE CARTÃO ***************************/

  //Regra de negócio para pessoa física vindo do ADMIN ou PARCEIRO DE VENDAS
  private loadExternalPf(uid, pid) {
    const groupedResponse = zip(this.userService.getUserFull(uid), this.planService.getPlanById(pid));
    groupedResponse.subscribe(res => {
      this.tipoPessoa = "PF";
      this.islinkExt = true;
      this.data = res[0] as UserCallback;

      //Regra
      this.plano = res[1] as Plan;
      console.log('retornos', res);
      const cartaoValor = this.plano.price * this.cartaoQuantidadeParcelas;
      const boletoValor = this.plano.price * this.cartaoQuantidadeParcelas;
      const boletoVencimento = this.loadDateExpiration();
      this.gerarValoresPagamento(cartaoValor, boletoValor, boletoVencimento);
    }, err => MsgUtil.msgErrorToast('Houve um erro ao carregar, por favor tente novamente mais tarde...'),
      () => { Spinner.spinner(document); }
    )
  }

  //Regra de negócio para pessoa jurídica vindo do ADMIN ou PARCEIRO DE VENDAS
  private loadExternalPj(sid, pid) {
    const groupedResponse = zip(this.companyService.getSubscriptionById(sid), this.planService.getPlanById(pid));
    groupedResponse.subscribe(res => {
      this.tipoPessoa = "PJ";
      this.islinkExt = true;
      this.subsId = sid;

      //Regra
      this.plano = res[1] as Plan;
      const companySubscription = res[0] as CompanySubscription;
      const cartaoValor = ((this.plano.price * companySubscription.maxSubscriptions) * this.cartaoQuantidadeParcelas);
      const boletoValor = ((this.plano.price * companySubscription.maxSubscriptions) * this.cartaoQuantidadeParcelas);
      const boletoVencimento = this.loadDateExpiration();
      this.gerarValoresPagamento(cartaoValor, boletoValor, boletoVencimento);
    }, err => MsgUtil.msgErrorToast('Houve um erro ao carregar, por favor tente novamente mais tarde...'),
      () => { Spinner.spinner(document); }
    )
  }

  //Regra de negócio para pessoa física que acabou de se cadastrar
  private loadData() {
    this.tipoPessoa = "PF";
    this.islinkExt = false;
    this.data = StepsUtil.getSteps().userCallback;

    //Regra
    this.plano = StepsUtil.getSteps().step1.plano;
    const cartaoValor = this.plano.price * this.cartaoQuantidadeParcelas
    const boletoValor = this.plano.price * this.cartaoQuantidadeParcelas;
    const boletoVencimento = this.loadDateExpiration();
    this.gerarValoresPagamento(cartaoValor, boletoValor, boletoVencimento);
  }

  private gerarValoresPagamento(cartaoValor?, boletoValor?, boletoVencimento?) {
    this.cartaoValor = cartaoValor
    this.boletoValor = boletoValor
    this.boletoVencimento = boletoVencimento;
    this.habilitaComponenteCartao = true;
  }

  /***************** REGRAS DE CARREGAMENTO DOS VALORES DE CARTÃO ***************************/

  pagamentoEvento(event: PagamentoRetorno) {
    if (event.pagamentoSelecionado === 'CARTAO') {
      this.processaDadosCartao(event);
      return;
    }

    if (event.pagamentoSelecionado === 'BOLETO') {
      this.cartaoToken = null;
      this.finalizaPagamento();
      return;
    }
  }

  /***************** REGRAS DE COMUNICAÇÃO ENTRE O COMPONENTE DO TOKEN IUGU ***************************/
  processaDadosCartao(event: PagamentoRetorno) {
    this.cartaoNumero = event.number;
    this.cartaoNome = event.name;
    this.cartaoCodigo = event.cvc;
    this.cartaoValidade = event.expiry;
    this.qtdParcelas = event.parcela;
    this.habilitaComponenteCartaoToken = true;
  }

  recebeTokenIugu(event) {
    this.habilitaComponenteCartaoToken = false;
    if (event !== 'ERRO') {
      console.log('recebendo token iugu', event);
      this.cartaoToken = event;
      this.finalizaPagamento();
    } else {
      MsgUtil.msgErrorToast('O cartão não é válido! Por favor, confira os dados inseridos e tente novamente.');
    }
  }
  /***************** REGRAS DE COMUNICAÇÃO ENTRE O COMPONENTE DO TOKEN IUGU ***************************/



  /***************** REGRAS DE PAGAMENTO ***************************/

  //Redireciona para finalização correta
  finalizaPagamento() {
    if (this.tipoPessoa === 'PF') this.finalizaPessoaFisica();
    if (this.tipoPessoa === 'PJ' && !this.islinkExt) this.finalizaPessoaJuridicaSite();
    if (this.tipoPessoa === 'PJ' && this.islinkExt) this.finalizaPessoaJuridicaExterno();
  }

  //Regra de negócio para pessoa física que acabou de se cadastrar, ou que veio do ADMIN Ou PARCEIRO DE VENDAS
  finalizaPessoaFisica() {
    Spinner.spinner(document, true);
    let payload: SubscriptionPurchase;
    if (!this.cartaoToken) {
      payload = {
        customerId: this.data.customer.id,
        paymentMethod: 'BANK_SLIP',
        planId: this.plano.id
      };
    } else {
      payload = {
        cardToken: this.cartaoToken,
        customerId: this.data.customer.id,
        month: Number(this.qtdParcelas),
        paymentMethod: 'CREDIT_CARD',
        planId: this.plano.id
      };
    }

    this.planService.setPlanToCustomer(payload).subscribe((r: any) => {
      StorageUtil.setResponse(r);
      if (!this.cartaoToken && r.subscription.pdf) {
        //get barcode
        this.planService.getSubscriptionBarcode(r.subscription.id).subscribe((s: SubscriptionBarcodeInterface) => {
          this.navigateSuccess(r.subscription.pdf, s.bankSlip.barcode);
        }, () => {
          this.navigateSuccess(r.subscription.pdf, null);
        });
      } else {
        this.navigateSuccess();
      }
    }, () => {
      MsgUtil.msgErrorToast('Houve um erro ao processar o pagamento, por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
  }

  //Regra de negócio para pessoa jurídica que acabou de se cadastrar
  finalizaPessoaJuridicaSite() {

  }

  //Regra de negócio para pessoa jurídica vindo do ADMIN ou PARCEIRO DE VENDAS
  finalizaPessoaJuridicaExterno() {
    Spinner.spinner(document, true);
    let payload: CompanySubscriptionPurchase;
    if (!this.cartaoToken) {
      payload = {
        companySubscriptionId: this.subsId,
        months: this.qtdParcelas,
        paymentMethod: 'BANK_SLIP'
      }
    } else {
      payload = {
        companySubscriptionId: this.subsId,
        months: this.qtdParcelas,
        paymentMethod: 'CREDIT_CARD',
        paymentToken: this.cartaoToken
      }
    }
    this.companyService.setPlanToCompany(payload).subscribe((response) => {
      this.navigateSuccess();

    }, () => {
      MsgUtil.msgErrorToast('Houve um erro ao processar o pagamento, por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
  }

  /***************** REGRAS DE PAGAMENTO ***************************/

  private navigateSuccess(bankSlipUrl?, bankSlipBarCode?) {
    let email = "";
    let tipoPagamento = "";
    if (!this.cartaoToken) {
      tipoPagamento = 'BOLETO';
    } else {
      tipoPagamento = 'CARTAO';
    }

    if (this.islinkExt) {
      if (this.tipoPessoa === "PF") email = this.data.prospect.email;
    } else {
      email = this.data.prospect.email;
    }

    this.ngZone.run(() => {
      this.router.navigate(['/sucesso-externo'], { queryParams: { tipoPessoa: this.tipoPessoa, tipoPagamento, email, social: this.social, bankSlipUrl, bankSlipBarCode } });
    });
  }


  //Helpers
  private loadDateExpiration() {
    const today = new Date();
    const date = today.setDate(today.getDate() + 3);
    return FormateDate.formateData(date);
  }

}
