import { AppleService } from './../../service/social/apple.service';
import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Util } from 'src/app/utils/util';
import { ApiService } from 'src/app/service/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { StorageUtil } from 'src/app/utils/storage';
import { UriKey } from 'src/app/utils/uri-service.service';
import { HttpUtil } from 'src/app/utils/http-util';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { PaymentRequest } from '../request';
import { FormateDate } from 'src/app/utils/formateDate';
import { StepsUtil } from 'src/app/utils/steps';
import { UserService } from 'src/app/service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Spinner } from 'src/app/utils/stpiner';
import { environment } from './../../../environments/environment';
import { PlanService } from 'src/app/service/plan.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';

declare var $;
declare var dataLayerPushRegisterPF;

@Component({
  selector: 'app-cadastro-pf',
  templateUrl: './cadastro-pf.component.html',
  styleUrls: ['./cadastro-pf.component.css']
})
export class CadastroPfComponent implements OnInit {
  
  registerFormGroup: FormGroup;
  registerForm: FormGroup;
  submitted = false;


  cep = Util.cepResponse();
  formControl = 'form-control';
  sexo = '';
  validAceitM = false;

  forcedCompany;
  removeFreePlan = environment.removeFreePlan;

  constructor(
    private _formBuilder: FormBuilder,
    private service: ApiService,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private planService: PlanService,
    private appleService: AppleService,
    private ngZone: NgZone
  ) {
    StorageUtil.clearSession();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  socialLogin(provider) {
    switch (provider) {
      case 'FACEBOOK':
        // Get Code
        console.log('https://www.facebook.com/v10.0/dialog/oauth?client_id=' + environment.facebook_client_id + '&redirect_uri=' + environment.facebook_redirect_uri + '&state=public_profile,email&scope=email');
        window.location.href = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=' + environment.facebook_client_id + '&redirect_uri=' + environment.facebook_redirect_uri + '&state=public_profile,email&scope=email';
        break;

      case 'GOOGLE':
        // Get Code
        console.log('https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&redirect_uri=' + environment.google_redirect_uri + '&client_id=' + environment.google_client_id);
        window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&redirect_uri=' + environment.google_redirect_uri + '&client_id=' + environment.google_client_id;
        break;

      case 'APPLE':
        this.appleService.loadApple();
        $('#appleid-signin').click();
        break;
    }
  }

  //Getting information from apple pop up
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if(event.origin === "https://appleid.apple.com") {
      const payload = this.appleService.responseApple(event);
      if(payload) {
        this.redirectApple(payload);
      } else {
        ErrorReturnUtil.buildError(null, 'Ocorreu um erro no login via Apple. Por favor, tente novamente mais tarde.')
      } 
    }    
  }

  redirectApple(payload) {
    Spinner.spinner(document);
    this.userService.socialLogin(payload).subscribe((r) => {
      if (r.jwtToken) {
        StepsUtil.insertSteps('stepSocial', { jwtToken: r.jwtToken, provider: 'APPLE' });
        this.ngZone.run(() => {
          this.router.navigate(['/planos']);
        });
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no cadastro via Google. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

  private loadform() {
    this.registerForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }


  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    Spinner.spinner(document, true);
    this.userService.checkUniqueUser(this.f['email'].value).subscribe(res => {
      StepsUtil.insertSteps('stepEmail', this.registerForm.value);
      this.router.navigate(['/planos']);
    }, (erro: HttpErrorResponse) => {
      MsgUtil.msgErrorToast(erro.error.message);
    }, () => Spinner.spinner(document));

  }

  public getCep() {
    this.service.getCep(this.cep.cep)
      .subscribe((r: any) => {
        this.cep = r;
      });
  }

  public getSexo(value) {
    this.sexo = value;
  }

  public register() {
    this.registerFormGroup.value.sexo = this.sexo;
    this.invalidPayload();

    const payload = PaymentRequest.payloadRegister(this.registerFormGroup.value, this.cep);

    this.service.post(payload, UriKey.LEADS, HttpUtil.HttpParamsAndPath())
      .subscribe((r) => {
        StorageUtil.setUser(r);
        this.router.navigate(['/codigo']);
      });
  }

  public invalidPayload() {
    const msgFrom = 'Apenas o complemento não é obrigatório';

    if (this.registerFormGroup.invalid) {
      throw EvalError(MsgUtil.msgErrorToast(msgFrom));
    }

    if (this.registerFormGroup.value.sexo == '') {
      throw EvalError(MsgUtil.msgErrorToast(msgFrom));
    }

    if (this.cep.bairro == '') {
      throw EvalError(MsgUtil.msgErrorToast(msgFrom));
    }

    if (!this.validAceitM) {
      throw EvalError(MsgUtil.msgErrorToast('Clique em Aceito o Contrato e os Termos de Uso do Doutorpass para continuar'));
    }

    this.validateDoc();
    this.invalidDate();
  }

  public validateDoc(): void {
    if (!ValidateUtil.validarCPF($('#doc').val())) {
      throw EvalError(MsgUtil.msgErrorToast('Documento inválido'));
    }
  }

  public invalidDate(): void {
    if (FormateDate.invalidDate(this.registerFormGroup.value.data_nascimento)) {
      throw EvalError(MsgUtil.msgErrorToast('Data inválida'));
    }
  }

  async ngOnInit() {
    this.checkPlanAndCompany(); //verificar se existe plano passado via querystring e se há deeplink informado
    this.loadform();
    dataLayerPushRegisterPF();
  }

  checkPlanAndCompany() {
    this.activatedRoute.queryParams.subscribe((r)=> {
      if(r.p) this.decodePlan(r.p);
      if(r.e) this.decodeCompany(r.e);
      if(r.from) {
        StorageUtil.setFromApp(r.from);
      }
      if(r.deeplink) {
        StorageUtil.setDeepLink(atob(r.deeplink));
      }
    });
  }

  decodePlan(encodedPlan) {
    this.getPlan(atob(encodedPlan));
  }

  decodeCompany(encodedCompany) {
    this.forcedCompany = atob(encodedCompany);
  }

  getPlan(planId: string) {
    this.planService.getPlanById(planId).subscribe((r: any) => {
      if(r.id) StorageUtil.forcePlan(r);
    });
  }

  ngOnDestroy() {
  }

}
