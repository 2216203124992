import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import {TableModule} from 'primeng/table';
import { HttpErrorInterceptor } from './utils/http-error.interceptor';
import {MessageService} from 'primeng/api';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {CarouselModule} from 'primeng/carousel';
import {CardModule} from 'primeng/card';
import { FooterComponent } from './footer/footer.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { Router, ɵROUTER_PROVIDERS } from '@angular/router';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import {CalendarModule} from 'primeng/calendar';
import {InputMaskModule} from 'primeng/inputmask';
import { CodigoComponent } from './home/codigo/codigo.component';
import { BoletoComponent } from './home/boleto/boleto.component';
import { FinalComponent } from './home/final/final.component';
import { PagamentoSucessoComponent } from './home/pagamento-sucesso/pagamento-sucesso.component';
import { PagamentoComponent } from './home/pagamento/pagamento.component';
import { CadastroPfComponent } from './home/cadastro-pf/cadastro-pf.component';
import { CadastroPjComponent } from './home/cadastro-pj/cadastro-pj.component';
import { PagamentoAnualComponent } from './home/pagamento/pagamento-anual/pagamento-anual.component';
import { PagamentoMensalComponent } from './home/pagamento/pagamento-mensal/pagamento-mensal.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import { environment } from 'src/environments/environment';
import { PlanosComponent } from './home/planos/planos.component';
import { StepperComponent } from './home/stepper/stepper.component';
import { DadosCadastraisComponent } from './home/dados-cadastrais/dados-cadastrais.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TempPagamentoComponent } from './home/temp-pagamento/temp-pagamento.component';
import { SucessoComponent } from './home/sucesso/sucesso.component';
import { SucessoExternoComponent } from './home/sucesso-externo/sucesso-externo.component';
import { FacebookReturnComponent } from './home/cadastro-pf/facebook-return/facebook-return.component';
import { GoogleReturnComponent } from './home/cadastro-pf/google-return/google-return.component';
import ptBr from '@angular/common/locales/pt';
import { NgxHotjarModule, NgxHotjarRouterModule, NgxHotjarService } from 'ngx-hotjar';
import { PagamentoRegraComponent } from './home/pagamento-modulo/pagamento-regra/pagamento-regra.component';
import { PagamentoComponenteComponent } from './home/pagamento-modulo/pagamento-componente/pagamento-componente.component';
import { CardModule as NewCardModule } from 'ngx-card/ngx-card';
import { PagamentoCartaoTokenComponent } from './home/pagamento-modulo/pagamento-cartao-token/pagamento-cartao-token.component';
import * as Sentry from "@sentry/angular";

registerLocaleData(ptBr)
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    FooterComponent,
    CodigoComponent,
    BoletoComponent,
    FinalComponent,
    PagamentoSucessoComponent,
    PagamentoComponent,
    CadastroPfComponent,
    CadastroPjComponent,
    PagamentoAnualComponent,
    PagamentoMensalComponent,
    PlanosComponent,
    StepperComponent,
    DadosCadastraisComponent,
    TempPagamentoComponent,
    SucessoComponent,
    SucessoExternoComponent,
    FacebookReturnComponent,
    GoogleReturnComponent,
    PagamentoRegraComponent,
    PagamentoComponenteComponent,
    PagamentoCartaoTokenComponent
  ],
  imports: [
    MessageModule,
    ToastModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputMaskModule,
    TableModule,
    CarouselModule,
    CardModule,
    AutoCompleteModule,
    CalendarModule,
    RadioButtonModule,
    NgxMaskModule.forRoot(),
    NgxHotjarModule.forRoot(environment.hotjar),
    NgxHotjarRouterModule,
    NewCardModule
  ],
  providers: [
    MessageService,
    ɵROUTER_PROVIDERS,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {provide: 'googleTagManagerId', useValue: 'GTM-NVNZWK5'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'pt-PT'},
    NgxHotjarService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
