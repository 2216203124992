import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageUtil } from '../utils/storage';
import { environment } from './../../environments/environment';
import { OtpChallengeOutout } from './outputs/otp-challenge.outout';

@Injectable({
    providedIn: 'root'
})
export class OtpService {
    headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'client_id': environment.client_id,
        'access_token': StorageUtil.getAccessToken()
    };

    constructor(private http: HttpClient) { }

    public challengeAsync(mobilePhone: string): Observable<OtpChallengeOutout> {
        const body: any = {
            mobilePhone: mobilePhone,
            seed: mobilePhone
        };

        const requestOptions = {
            headers: new HttpHeaders(this.headers)
        };

        const request = this.http.post<OtpChallengeOutout>(`${environment.urlBase}otp/challenge`, body, requestOptions);
        return request;
    }

    public validateAsync(code: string, seed: string, token: string): Observable<boolean> {
        const body: any = {
            code: code,
            seed: seed,
            token: token
        };

        const requestOptions = {
            headers: new HttpHeaders(this.headers)
        };

        const request = this.http.post(`${environment.urlBase}otp/validate`, body, requestOptions)
            .pipe(map((data: any) => {
                console.log('DATA SERVICE',  data)
                return true;
            }), catchError((err) => {
                console.log('ERRO',  err)
                return of(false)
            }));

        return request;
    }
}
