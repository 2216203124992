import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from './msgUtil';

export class ErrorReturnUtil {
    public static buildError(error: HttpErrorResponse, customMessage?: string) {
        if(customMessage) {
            MsgUtil.msgErrorToast(customMessage);
            return;
        }

        if (error.error.errors) {
            for (const errors of error.error.errors) {
                MsgUtil.msgErrorToast(errors.message);
            }
        } else {
            error.error.message ? MsgUtil.msgErrorToast(error.error.message) : MsgUtil.msgErrorToast("Encontramos um erro. Por favor, tente novamente mais tarde.")
        }
        return;
    }
}