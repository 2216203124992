import { OAuthService } from './../../o-auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Plan } from 'src/app/models/plan';
import { RegisterCustomer } from 'src/app/models/register-customer';
import { UserCallback } from 'src/app/models/user-callback';
import { OtpService } from 'src/app/service/otp.service';
import { UserService } from 'src/app/service/user.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { MustMatch } from 'src/app/utils/must-match';
import { StepsUtil } from 'src/app/utils/steps';
import { Spinner } from 'src/app/utils/stpiner';
import { validarCPF } from 'src/app/utils/validateUtilForm';

@Component({
  selector: 'app-dados-cadastrais',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.css']
})
export class DadosCadastraisComponent implements OnInit, OnDestroy {

  plano: Plan;
  registerForm: FormGroup;
  submitted = false;
  numOfDigits = 4;
  mobileToken;
  mobileNumber;
  loading: boolean = false;
  validated: boolean = false;

  mobileValidation: boolean = false;

  subscription: Subscription;

  private subject: Subject<string> = new Subject();


  @ViewChildren('inputs') inputs: QueryList<any>;
  confirmCodeForm: FormGroup;

  socialJwt;
  socialLogin = false;
  socialUser: UserCallback

  // Contact Number Validation
  contactNumberValidation = false;
  contactNumberValidated = false;
  contactNumberValidationToken;
  contactNumberCodeByUser;

  constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService, private opt: OtpService, private oAuthService: OAuthService) {
    !StepsUtil.hasSteps() ? this.router.navigate(['/']) : true;

    this.socialJwt = StepsUtil.getSteps().stepSocial;
    if (this.socialJwt) {
      this.socialLogin = true;
      const tokenDecoded = this.oAuthService.getDecodedAccessToken(this.socialJwt.jwtToken);
      const userId = tokenDecoded['system-user'];

      this.userService.getUserFull(userId).subscribe((userCallback: UserCallback) => {
        if (StepsUtil.getSteps().step1.plano.name === "Plano básico") {
          StepsUtil.insertSteps("userCallback", userCallback);
          this.redirectAfterRegister(userCallback.prospect.email, true);
          return;
        } else {
          this.socialUser = userCallback;
          this.registerForm.patchValue({ name: userCallback.user.name });
          this.registerForm.controls.name.disable();
        }
      })
    }

    this.confirmCodeForm = this.formBuilder.group({
      digits: this.formBuilder.array([])
    });
  }

  ngOnInit() {
    const steps = StepsUtil.getSteps();
    this.plano = steps.step1.plano;
    this.loadForm();

    this.subscription = this.subject.pipe(
      debounceTime(1500)
    ).subscribe(mobile => {
      this.loading = true;
      this.mobileNumber = mobile;
      this.opt.challengeAsync(mobile).subscribe(token => {
        this.mobileToken = token.token;
      }, err => MsgUtil.msgError('Houve um erro ao enviar para este número'),
        () => this.loading = false
      );

    })

    for (let i = 0; i < this.numOfDigits; i++) {
      (this.confirmCodeForm.get('digits') as FormArray).push(this.formBuilder.control(null))
    }
  }

  loadForm() {
    if (this.socialLogin) {
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        document: ['', Validators.required],
        phone: ['', [Validators.required, Validators.minLength(10)]],
      }, {
        validator: [validarCPF('document')]
      });
    } else {
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        document: ['', Validators.required],
        phone: ['', [Validators.required, Validators.minLength(10)]],
        password: ['', [
          Validators.required,
          Validators.minLength(8),
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        ]],
        confirmPassword: ['', Validators.required]
      }, {
        validator: [validarCPF('document'), MustMatch('password', 'confirmPassword')]
      });
    }
  }


  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // Phone verification
    if (!this.contactNumberValidated) {
      return;
    }

    if (this.socialLogin) {
      this.updateUser();
    } else {
      this.createUser();
    }
  }

  createUser() {
    Spinner.spinner(document, true);
    StepsUtil.insertSteps('step2', this.registerForm.getRawValue());
    const steps = StepsUtil.getSteps();
    const registerCustomer: RegisterCustomer = {
      contactNumber: steps.step2.phone,
      prospect: {
        documentNumber: steps.step2.document,
        email: steps.stepEmail.email
      },
      systemUser: {
        name: steps.step2.name,
        password: steps.step2.password,
        passwordConfirmation: steps.step2.confirmPassword,
        userName: steps.stepEmail.email
      }
    };

    this.userService.registerCustomer(registerCustomer).subscribe((userCallback: UserCallback) => {
      StepsUtil.insertSteps("userCallback", userCallback);
      this.redirectAfterRegister(userCallback.prospect.email, null);
    }, (error: HttpErrorResponse) => {
      ErrorReturnUtil.buildError(error);
    }, () => Spinner.spinner(document));
  }

  updateUser() {
    Spinner.spinner(document, true);
    StepsUtil.insertSteps('step2', this.registerForm.getRawValue());
    const payload = {
      contactNumber: this.registerForm.getRawValue().phone,
      documentNumber: this.registerForm.getRawValue().document,
    };

    this.userService.updateCustomer(this.socialUser.customer.id, payload, this.socialJwt.jwtToken).subscribe((r) => {
      MsgUtil.msgSuccessToast('Dados salvos com sucesso!');
      this.userService.getUserFull(this.socialUser.user.id).subscribe((userCallback: UserCallback) => {
        StepsUtil.insertSteps("userCallback", userCallback);
        this.redirectAfterRegister(this.socialUser.prospect.email, true);
      });
    },
      err => MsgUtil.msgErrorToast('Ocorreu um erro na atualização dos dados. Tente novamente.'),
      () => Spinner.spinner(document)
    );
  }

  redirectAfterRegister(email, social) {
    //MELHORAR VERIFICAÇÃO DO PLANO - REFATORAR
    if (this.plano.name === "Plano básico") {
      this.router.navigate(['/sucesso-externo'], { queryParams: { tipoPessoa: 'pf', tipoPagamento: '', email, social } });
    } else {
      this.router.navigate(['/pag'], { queryParams: { social } });
    }
  }

  contactNumberStartValidation() {
    Spinner.spinner(document, true);
    this.contactNumberValidation = true;
    this.f.phone.disable();
    this.opt.challengeAsync(this.f.phone.value).subscribe(token => {
      this.contactNumberValidationToken = token.token;
    }, err => MsgUtil.msgError('Houve um erro ao enviar para este número'),
      () => Spinner.spinner(document)
    );
  }

  contactNumberCheckValidation(e: string) {
    if (e.length === 4) {
      Spinner.spinner(document, true);
      this.opt.validateAsync(e, this.f.phone.value, this.contactNumberValidationToken).subscribe(res => {
        if (res) {
          this.contactNumberValidated = true;
          MsgUtil.msgSuccessToast('Telefone validado!');
        } else {
          ErrorReturnUtil.buildError(null, 'Houve um erro ao validar!');
        }
      }, err => {
        console.log(err);
        ErrorReturnUtil.buildError(err, 'Houve um erro ao validar!');
      }, () => Spinner.spinner(document));
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
