import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/utils/util';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { UriKey } from 'src/app/utils/uri-service.service';
import { HttpUtil } from 'src/app/utils/http-util';
import { StorageUtil } from 'src/app/utils/storage';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { FormateDate } from 'src/app/utils/formateDate';
import { PaymentRequest } from '../request';

declare var $;
declare var dataLayerPushRegisterPJ;

@Component({
  selector: 'app-cadastro-pj',
  templateUrl: './cadastro-pj.component.html',
  styleUrls: ['./cadastro-pj.component.css']
})
export class CadastroPjComponent implements OnInit {

  registerFormGroup: FormGroup;
  cep = Util.cepResponse();
  formControl = 'form-control';
  sexo = '';
  qtdvidas: any = 1;
  validAceitM = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: ApiService,
    private router: Router
  ) { }

  private loadFromGroup(): void {
    this.registerFormGroup = this.formBuilder.group({
      nome: ['', [Validators.required]],
      documento: ['', [Validators.required]],
      nome_responsavel_financeiro: ['', [Validators.required]],
      sobrenome: ['', [Validators.required]],
      documento_responsavel_financeiro: ['', [Validators.required]],
      qtd_vidas: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      email: ['', [Validators.required]],
      cep: ['', [Validators.required]],
      numero_endereco: ['', [Validators.required]],
      complemento: ['']
    });
  }

  public getCep() {
    this.service.getCep(this.cep.cep)
      .subscribe((r: any) => {
        this.cep = r;
      });
  }

  public register() {
    this.registerFormGroup.value.sexo = this.sexo;
    this.invalidPayload();

    const payload = PaymentRequest.payloadRegisterPj(this.registerFormGroup.value, this.cep);
    dataLayerPushRegisterPJ(payload.qtd_vidas);
    this.service.post(payload, UriKey.LEADS_EMPRESA, HttpUtil.HttpParamsAndPath())
                .subscribe((r) => {
                  StorageUtil.setUser(r);
                  this.router.navigate(['/codigo']);
                });
  }

  public invalidPayload() {

    if (this.registerFormGroup.invalid) {
      throw EvalError(MsgUtil.msgErrorToast('Apenas o complemento não é obrigatório'));
    }

    if (this.cep.bairro == '') {
      throw EvalError(MsgUtil.msgErrorToast('Apenas o complemento não é obrigatório'));
    }

    if (!this.validAceitM) {
      throw EvalError(MsgUtil.msgErrorToast('Clique em Aceito o Contrato e os Termos de Uso do Doutorpass para continuar'));
    }

    this.validateDocPf();
  }

  public validateDocPf(): void {
    if (!ValidateUtil.validarCPF($('#doc').val())) {
      throw EvalError(MsgUtil.msgErrorToast('Documento inválido'));
    }
  }

  public validateDocPj(): void {
    if (!ValidateUtil.validateCNPJ($('#cnpj').val())) {
      throw EvalError(MsgUtil.msgErrorToast('Documento inválido'));
    }
  }

  public plus() {
    this.qtdvidas++;
  }

  public less() {
    if (this.qtdvidas > 1) {
      this.qtdvidas--;
    }
  }

  public justNumber(event) {
    this.qtdvidas = event.target.value.replace(/\D+/g, '');
  }

  ngOnInit() {
    this.loadFromGroup();
  }
}
