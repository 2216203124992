export const environment = {
  production: false,
  environment: 'hml',
  client_id: '12075ed4-5a38-3e51-910f-0a6a2275e9d3',
  passWord: '09856cbc-cfcf-3392-95bd-01a35c36f6f1',
  urlBaseOauth: 'https://api-athenasaude.sensedia.com/homologacao/sandbox/oauth/',
  urlBase: 'https://api-athenasaude.sensedia.com/homologacao/drpass/v2/',
  cep: 'https://api-athenasaude.sensedia.com/homologacao/drpass/administracao/v1/enderecos/',
  public_key: 'TEST-d050dcf0-cc3f-4d76-a7c9-0f4ef320297f',
  iugu_account_Id: 'CB0E71BC058C0A9555FBF85AD3631DC7',
  url_iugu_token: 'https://api.iugu.com/v1/payment_token',
  urlMP: 'https://api.mercadopago.com/v1/',
  sms: 'https://api-athenasaude.sensedia.com/desenvolvimento/sms/v1/',
  url: 'https://hml-web.doutorpass.com/',
  url_payment: 'https://hml-payment.doutorpass.com/',

    // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyB_LwwHHHmbfL7RQ_j63mHhAy2g4HdRCps',
    authDomain: 'doutor-pass.firebaseapp.com',
    projectId: 'doutor-pass',
    storageBucket: 'doutor-pass.appspot.com',
    messagingSenderId: '12398399454',
    appId: '1:12398399454:web:798b0090894e5bc754574f',
    measurementId: 'G-YZZWN89NFT'
  },

  // Login Facebook
  facebook_client_id: '804615003529486',
  facebook_redirect_uri: 'https://hml-payment.doutorpass.com/returnfb',

  //Login Google
  google_client_id: '170137183142-5ma3pvvevat67l0l0b3fatj8qsst2mds.apps.googleusercontent.com',
  google_redirect_uri: 'https://hml-payment.doutorpass.com/returngoogle',
  google_user_data_url: 'https://www.googleapis.com/oauth2/v2/userinfo',

  //Login Apple
  apple_client_id: 'com.doutorpass.loginappleservice',
  apple_redirect_uri: 'https://hml-payment.doutorpass.com',

  externalHome: 'https://www.doutorpass.com/',

  //Hotjar
  hotjar: '1955634',

  removeFreePlan: false
};
