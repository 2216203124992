import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Spinner } from 'src/app/utils/stpiner';
import { environment } from 'src/environments/environment';
declare var Iugu;
declare var $;

@Component({
  selector: 'app-pagamento-cartao-token',
  templateUrl: './pagamento-cartao-token.component.html',
  styleUrls: ['./pagamento-cartao-token.component.css']
})
export class PagamentoCartaoTokenComponent implements OnInit {
  @Input() number;
  @Input() name;
  @Input() expiration;
  @Input() verification;
  @Output() respostaToken = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
    this.loadIugu();
    this.insertValuesAndSubmit();
  }

  private loadIugu() {
    let token = '';
    Iugu.setAccountID(environment.iugu_account_Id);

    if (!environment.production) {
      Iugu.setTestMode(true);
    }

    $('#payment-iugu').submit(function (evt) {
      Spinner.spinner(document, true);
      var form = $(this);
      var tokenResponseHandler = function (data) {
        Spinner.spinner(document);
        if (data.errors) {
          console.log("Erro salvando cartão: ", data.errors);
          $("#token").val('ERRO');
          setTimeout(function(){ $("#enviarToken").click(); }, 200);
        } else {
          console.log('token', data.id);
          $("#token").val(data.id);
          $("#enviarToken").click();
        }
      }
      Iugu.createPaymentToken(this, tokenResponseHandler);
      return false;
    });
  }

  enviarToken() {
    this.respostaToken.emit($("#token").val());
  }

  insertValuesAndSubmit() {
    $('#numeroCartao').val(this.number);
    $('#nomeCartao').val(this.name);
    $('#expiration').val(this.expiration.replaceAll(' ', ''));
    $('#segCode').val(this.verification);
    $("#payment-iugu").submit();
  }
}
