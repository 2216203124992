
import { UriKey } from 'src/app/utils/uri-service.service';
import { Card } from 'src/app/utils/card';
import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, ɵConsole } from '@angular/core';
import { Subscription, zip } from 'rxjs';
import { FormateDate } from 'src/app/utils/formateDate';
import { StorageUtil } from 'src/app/utils/storage';
import { NgZone } from '@angular/core';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StepsUtil } from 'src/app/utils/steps';
import { UserCallback } from 'src/app/models/user-callback';
import { PlanService } from 'src/app/service/plan.service';
import { SubscriptionPurchase } from 'src/app/models/subscription-purchase';
import { Plan } from 'src/app/models/plan';
import { Spinner } from 'src/app/utils/stpiner';
import { UserService } from 'src/app/service/user.service';
import { CompanySubscriptionPurchase } from 'src/app/models/companySubscription-purchase';
import { CompanyService } from 'src/app/service/company.service';
import { CompanySubscription } from 'src/app/models/company-subscription';
import { SubscriptionBarcodeInterface } from 'src/app/models/subscription-barcode';

declare var cardValidator;
declare var dataLayerPushPayment;
declare var $;
declare var Iugu;
const valorVIda = 49.9;

@Component({
  selector: 'app-temp-pagamento',
  templateUrl: './temp-pagamento.component.html',
  styleUrls: ['./temp-pagamento.component.css']
})
export class TempPagamentoComponent implements OnInit {

  payload = Card.loadpayloadCard();
  card = Card.inputCard();
  companySubscription: CompanySubscription;
  data: UserCallback;
  plano: Plan;
  pagador = [this.payload];
  readonly = false;
  btnFinalizar = 'CARD';
  dataVencimento;
  cardValid;
  imgCard: string;
  typeCard: any = '';
  pf: boolean;
  pj: boolean;
  subsId: string;
  paymentEvent$ = new EventEmitter<any>();
  paymentEventErro$ = new EventEmitter<any>();
  Subscription = new Subscription();
  qtdParcelas: number = 1;
  valor_vida = 29.9;
  islinkExt: boolean = false;

  social: string = '';

  constructor(
    private service: ApiService,
    private router: Router,
    private ngZone: NgZone,
    private planService: PlanService,
    private route: ActivatedRoute,
    private userService: UserService,
    private companyService: CompanyService
  ) {
    this.route.queryParams.subscribe(params => {
      Spinner.spinner(document, true);
      if (params['uId'] && params['pId']) {
        this.loadPf(params['uId'], params['pId']);
      } else if (params['sId'] && params['pId']) {
        this.loadPj(params['sId'], params['pId']);
      } else {
        this.loadData();
        Spinner.spinner(document);
      }

      if (params['social']) {
        this.social = params['social'];
      }

    });
  }

  public onReadOnly() {
    if (this.readonly) {
      this.readonly = false;
      this.pagador.forEach((element) => {
        element.celular = '';
        element.documento = '';
        element.email = '';
        element.nome = '';
        element.sobrenome = '';
      });
      $('#nomeCartao').val('');
    } else {
      this.readonly = true;
      const user = this.data;

      this.pagador.forEach((element) => {
        element.celular = user.customer.contactNumber.toString();
        element.documento = user.prospect.documentNumber.toString();
        element.email = user.prospect.email
        element.nome = user.user.name;
      });

      this.card.nome_cartao = user.user.name;
      $('#nomeCartao').val(user.user.name);
    }
  }

  private loadPf(uid, pid) {
    const groupedResponse = zip(this.userService.getUserFull(uid), this.planService.getPlanById(pid));
    groupedResponse.subscribe(res => {
      this.data = res[0] as UserCallback;
      this.plano = res[1] as Plan;
      this.pf = true;
      this.islinkExt = true;
    }, err => MsgUtil.msgErrorToast('Houve um erro ao carregar, por favor tente novamente mais tarde...'),
      () => { Spinner.spinner(document); }
    )
  }

  private loadPj(sid, pid) {
    const groupedResponse = zip(this.companyService.getSubscriptionById(sid), this.planService.getPlanById(pid));
    groupedResponse.subscribe(res => {
      this.companySubscription = res[0] as CompanySubscription;
      this.plano = res[1] as Plan;
      this.pj = true;
      this.islinkExt = true;
      this.subsId = sid;
    }, err => MsgUtil.msgErrorToast('Houve um erro ao carregar, por favor tente novamente mais tarde...'),
      () => { Spinner.spinner(document); }
    )
  }

  public tipoPagamento(value) {
    this.btnFinalizar = value;
  }

  public registrarPagador() {

    this.invalidPayload();

    if (this.payload.tipo_pagamento == 'BOLETO') {
      this.boleto();
    } else {
      this.pagamentoMensal();
    }
  }

  private pagamentoMensal() {
    this.generateTokenIugu();
  }

  public setTipoPagamento(event): void {
    this.payload.tipo_pagamento = event;
  }

  public validateDoc(): void {
    if (!ValidateUtil.validarCPF(this.pagador[0].documento)) {
      throw EvalError(MsgUtil.msgErrorToast('Documento inválido'));
    }
  }

  public validCard(event) {
    event.target.value = event.target.value.replace(/\D+/g, '');
    const card = cardValidator(event.target.value);
    if (card) return
    else {
      MsgUtil.msgErrorToast('Cartão inválido');
      this.imgCard = '';
      this.typeCard = '';
    }

  }

  public generateTokenIugu() {
    $('#submitPagamentoMensal').click();
  }

  private finalizarPagamento(token, boleto?: boolean) {
    if (this.pf) {
      this.pagarPlanoPF(token, boleto);
    } else if (this.pj) {
      this.pagarPlanoPj(token, boleto);
    }
  }

  private pagarPlanoPj(token, boleto?: boolean) {
    Spinner.spinner(document, true);

    let payload: CompanySubscriptionPurchase;
    if (boleto) {
      payload = {
        companySubscriptionId: this.subsId,
        months: this.qtdParcelas,
        paymentMethod: 'BANK_SLIP'
      }
    } else {
      payload = {
        companySubscriptionId: this.subsId,
        months: this.qtdParcelas,
        paymentMethod: 'CREDIT_CARD',
        paymentToken: token
      }
    }

    this.companyService.setPlanToCompany(payload).subscribe((response) => {
      this.navigateSuccess();

    }, () => {
      MsgUtil.msgErrorToast('Houve um erro ao processar o pagamento, por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
  }

  private pagarPlanoPF(token, boleto?: boolean) {
    Spinner.spinner(document, true);
    let payload: SubscriptionPurchase;

    if (boleto) {
      payload = {
        customerId: this.data.customer.id,
        paymentMethod: 'BANK_SLIP',
        planId: this.plano.id
      };
    } else {
      payload = {
        cardToken: token,
        customerId: this.data.customer.id,
        paymentMethod: 'CREDIT_CARD',
        planId: this.plano.id
      };
    }


    this.planService.setPlanToCustomer(payload).subscribe((r: any) => {
      StorageUtil.setResponse(r);
      if (boleto && r.subscription.pdf) {
        //get barcode
        this.planService.getSubscriptionBarcode(r.subscription.id).subscribe((s: SubscriptionBarcodeInterface) => {
          this.navigateSuccess(r.subscription.pdf, s.bankSlip.barcode);
        }, () => {
          this.navigateSuccess(r.subscription.pdf, null);
        });
      } else {
        this.navigateSuccess();
      }
    }, () => {
      MsgUtil.msgErrorToast('Houve um erro ao processar o pagamento, por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
  }

  private navigateSuccess(bankSlipUrl?, bankSlipBarCode?) {

    let tipoPessoa;
    if (this.pf) {
      tipoPessoa = "pf";
    } else if (this.pj) {
      tipoPessoa = "pj";
    }

    let email = "";
    if (this.islinkExt) {
      if (tipoPessoa === "pf") email = this.data.prospect.email;
    } else {
      email = this.data.prospect.email;
    }

    this.ngZone.run(() => {
      this.router.navigate(['/sucesso-externo'], { queryParams: { tipoPessoa: tipoPessoa, tipoPagamento: this.payload.tipo_pagamento, email, social: this.social, bankSlipUrl, bankSlipBarCode } });
    });
  }

  private invalidPayload() {
    const msg = 'Todos os campos são obrigatórios';
    this.setValueCard();

    if (this.payload.tipo_pagamento != 'BOLETO') {
      if (this.card.codgo_seguranca_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.nome_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.numero_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.vencimento_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      this.payload.parcelas = this.card.parcelas;
    }

  }

  private boleto() {
    this.finalizarPagamento(null, true);
  }

  getQuantidadeParcelas() {
    console.log(this.qtdParcelas);
  }

  private loadpayment_methods() {
    this.service.loadpayment_methods().subscribe((r) => {
      this.cardValid = r.filter((option) => option.status == 'active');
    });
  }

  private loadFinalizarPagamentoMensal() {
    this.paymentEvent$.subscribe((token) => {
      this.finalizarPagamento(token);
    });
  }

  private loadFinalizarPagamentoMensalError() {
    this.paymentEventErro$.subscribe((error) => {
      this.mapErroIugu(error);
    });
  }

  private mapErroIugu(erro: string) {
    if (erro.indexOf('last_name') > -1) {
      return MsgUtil.msgErrorToast('Sobrenome é obrigatório');
    }
    if (erro.indexOf('first_name') > -1) {
      return MsgUtil.msgErrorToast('Verifique se há espaços antes do nome');
    }
    if (erro.indexOf('number') > -1) {
      return MsgUtil.msgErrorToast('Cartão inválido');
    }
    if (erro.indexOf('expiration') > -1) {
      return MsgUtil.msgErrorToast('Data inválida');
    }
    if (erro.indexOf('verification_value') > -1) {
      return MsgUtil.msgErrorToast('CVV inválido');
    }
  }

  private setValueCard() {
    this.card.nome_cartao = $('#nomeCartao').val();
    this.card.codgo_seguranca_cartao = $('#segCode').val();
    this.card.numero_cartao = $('#numeroCartao').val();
    this.card.parcelas = '0';
    this.card.vencimento_cartao = $('#expiration').val();
  }

  private loadPaymentForm() {
    const paymentEvent$ = this.paymentEvent$;
    let token = '';

    Iugu.setAccountID(environment.iugu_account_Id);

    if (!environment.production) {
      Iugu.setTestMode(true);
    }

    const paymentEventErro$ = this.paymentEventErro$;

    $('#payment-form').submit(function (evt) {
      const tokenResponseHandler = function (data) {
        if (data.errors) {
          paymentEventErro$.emit(JSON.stringify(data.errors));
          return;
        }

        token = data.id;
        paymentEvent$.emit(token);
      };
      Iugu.createPaymentToken(this, tokenResponseHandler);
    });
  }

  private loadDateExpiration() {
    const today = new Date();
    const date = today.setDate(today.getDate() + 3);
    this.dataVencimento = FormateDate.formateData(date);
  }

  private loadData() {
    // TRATAR SE FOR VENDA PARA PJ
    this.data = StepsUtil.getSteps().userCallback;
    this.pf = true;
    this.plano = StepsUtil.getSteps().step1.plano;
  }

  ngOnInit() {
    this.loadDateExpiration();
    this.loadPaymentForm();
    this.loadpayment_methods();
    this.loadFinalizarPagamentoMensal();
    this.loadFinalizarPagamentoMensalError();
  }

  ngOnDestroy() {
    this.Subscription.unsubscribe();
  }

}
