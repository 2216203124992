import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageUtil } from './../utils/storage';
import { SubscriptionPurchase } from '../models/subscription-purchase';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    'client_id': environment.client_id,
    'access_token': StorageUtil.getAccessToken() 
  };

  constructor(private http: HttpClient) { }

  getPlans() {
    const requestOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(`${environment.urlBase}plans`, requestOptions);
  }

  getPlan(name) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(`${environment.urlBase}open/find-plans?planName=` + name, requestOptions);
  }

  getPlanById(id: string) {
    console.log(this.headers)
    const requestOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(`${environment.urlBase}open/find-plans/` + id, requestOptions);
  }

  setPlanToCustomer(data: SubscriptionPurchase) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(`${environment.urlBase}subscriptions/purchase`,data, requestOptions);
  }

  setPlanToCustomerPV(cid, pid, data: SubscriptionPurchase) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.post(`${environment.urlBase}subscriptions/${cid}/${pid}/purchase`,data, requestOptions);
  }

  getSubscriptionBarcode(subscriptionId: string) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers),
    };
    return this.http.get(`${environment.urlBase}open/subscription/${subscriptionId}/payment`, requestOptions);
  }

}
