import { StorageUtil } from 'src/app/utils/storage';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Plan } from 'src/app/models/plan';
import { PlanService } from 'src/app/service/plan.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { StepsUtil } from 'src/app/utils/steps';
import { Spinner } from 'src/app/utils/stpiner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.css']
})
export class PlanosComponent implements OnInit {

  forcedPlan: Plan;
  removeFreePlan = environment.removeFreePlan;


  constructor(private router: Router, private planService: PlanService) {
    !StepsUtil.hasSteps() ? this.router.navigate(['/']) : true;
  }

  ngOnInit() {
    this.checkForcedPlan();
  }

  checkForcedPlan() {
    if(StorageUtil.getForcedPlan()) this.forcedPlan = StorageUtil.getForcedPlan();
  }

  setPlan(plan) {
    Spinner.spinner(document, true);
    this.planService.getPlan(plan).subscribe((r: Plan) => {
      StepsUtil.insertSteps('step1', {plano: r});
      this.router.navigate(['/dados-cadastrais']);
    }, (error: HttpErrorResponse) =>{
      ErrorReturnUtil.buildError(error);
    },() => Spinner.spinner(document));
  }

}
