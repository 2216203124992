import { FormGroup } from '@angular/forms';
import { ValidateUtil } from './validateUtil';

export function validarCPF(document: string) {

    return (formGroup: FormGroup) => {
        const control = formGroup.controls[document];

        if (control.errors && !control.errors.validarCPF) {
            return;
        }

        const result = ValidateUtil.validarCPF(control.value);

        if (!result) {
            control.setErrors({ validarCPF: true });
        } else {
            control.setErrors(null);
        }
    };
}
