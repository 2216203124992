import { environment } from './../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { StorageUtil } from '../utils/storage';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  url = environment.externalHome;
  constructor(private router: Router) {
   }

  ngOnInit() {
  }
}
