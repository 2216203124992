import { environment } from 'src/environments/environment';
const CryptoJS = require('crypto-js');

export class StorageUtil {

    private static REGISTER = 'REGISTER';
    private static R_PAGAMENTO = 'R_PAGAMENTO';
    private static STEPS = 'STEPS';

    public static setUser(data: any): void {
        sessionStorage.setItem(StorageUtil.REGISTER, JSON.stringify( data ));
    }

    public static getUser(): any {
        return JSON.parse( sessionStorage.getItem( StorageUtil.REGISTER ) );
    }

    public static setRegisterSteps(data: any): void {
        sessionStorage.setItem(StorageUtil.STEPS, JSON.stringify( data ));
    }

    public static getRegisterSteps(): any {
        return JSON.parse( sessionStorage.getItem( StorageUtil.STEPS ) );
    }

    public static setAccessToken(data: any): void {
        localStorage.setItem('at', data);
    }

    public static getAccessToken(): any {
        return localStorage.getItem('at');
    }

    public static setResponse( data ) {
        sessionStorage.setItem(StorageUtil.R_PAGAMENTO, JSON.stringify( data ));
    }

    public static getPaymentResponse(): any {
        return JSON.parse( sessionStorage.getItem( StorageUtil.R_PAGAMENTO ) );
    }

    public static forcePlan( data ) {
        sessionStorage.setItem('FORCED_PLAN', JSON.stringify( data ));
    }

    public static getForcedPlan(): any {
        return JSON.parse( sessionStorage.getItem('FORCED_PLAN') );
    }

    public static setDeepLink( data ) {
        sessionStorage.setItem('DEEP_LINK', JSON.stringify( data ));
    }

    public static getDeepLink(): any {
        return JSON.parse( sessionStorage.getItem('DEEP_LINK') );
    }

    public static setFromApp( data ) {
        sessionStorage.setItem('FROM_APP', JSON.stringify( data ));
    }

    public static getFromApp(): any {
        return JSON.parse( sessionStorage.getItem('FROM_APP') );
    }

    public static clearSession(): void {
        sessionStorage.clear();
    }
}
