import { EventEmitter } from '@angular/core';

export class MsgUtil {

  public static addSingle$ = new EventEmitter<any>();
  public static clear$ = new EventEmitter<any>();

  public static msgErrorToast(msg) {

    setTimeout(() => {
      MsgUtil.addSingle$.emit({ key: 'toast', severity: 'error', summary: 'Algo errado!', detail: msg });
    }, 100);
    return msg;
  }


  public static msgError(msg) {

    setTimeout(() => {
      MsgUtil.addSingle$.emit({ severity: 'error', summary: 'Algo errado!', detail: msg });
    }, 100);
    return msg;
  }


  public static msgSuccessToast(msg) {

    setTimeout(() => {
      MsgUtil.addSingle$.emit({ key: 'toast', severity: 'success', summary: 'Tudo certo!', detail: msg });
    }, 100);

    return msg;
  }

  public static msgInfoToast(msg) {

    setTimeout(() => {
      MsgUtil.addSingle$.emit({ key: 'toast', severity: 'info', summary: 'Informação', detail: msg });
    }, 100);

    return msg;
  }


  public static clear() {
    setTimeout(() => {
      MsgUtil.clear$.emit();
    }, 100);
  }
}
