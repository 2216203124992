import { Component, OnInit } from '@angular/core';
import { StorageUtil } from 'src/app/utils/storage';
import { FormateDate } from 'src/app/utils/formateDate';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { UriKey } from 'src/app/utils/uri-service.service';
import { Card } from 'src/app/utils/card';

declare var cardValidator;
declare var dataLayerPushPayment;
declare var $;

@Component({
  selector: 'app-pagamento-anual',
  templateUrl: './pagamento-anual.component.html',
  styleUrls: ['./pagamento-anual.component.css']
})
export class PagamentoAnualComponent implements OnInit {

  payload = Card.loadpayloadCard();
  card = Card.inputCard();

  data = StorageUtil.getUser();
  pagador = [this.payload];
  readonly = false;
  btnFinalizar = 'CARD';
  dataVencimento;
  cardValid;
  imgCard: string;
  typeCard: any = '';
  pf;
  valor_vida = 24.9;
  preco_mes: any = this.data.qtd_vidas * this.valor_vida;
  parcelas12: any = (this.data.qtd_vidas * this.valor_vida) * 6;

  constructor(
    private service: ApiService,
    private router: Router,
  ) { }

  public onReadOnly() {
    if (this.readonly) {
      this.readonly = false;
      this.pagador.forEach((element) => {
        element.celular = '';
        element.documento = '';
        element.email = '';
        element.nome = '';
        element.sobrenome = '';
      });
    } else {
      this.readonly = true;
      const user = StorageUtil.getUser();

      this.pagador.forEach((element) => {
        element.celular = user.celular;
        element.documento = user.documento;
        element.email = user.email;
        element.nome = user.nome;
      });

      this.card.nome_cartao = user.nome;
    }
  }

  public tipoPagamento(value) {
    this.btnFinalizar = value;
  }

  public registrarPagador() {

    this.invalidPayload();

    if (this.payload.tipo_pagamento == 'BOLETO') {
      this.boleto();
    } else {
      this.cartao();
    }
  }

  private boleto() {
    let uri = '';

    if (StorageUtil.getUser().documento.length == 14) {
      uri = UriKey.LEADS_PJ;
      dataLayerPushPayment('PJ', 'Boleto', this.parcelas12, this.data.qtd_vidas);
    } else {
      uri = UriKey.LEADS;
      dataLayerPushPayment('PF', 'Boleto', this.valor_vida, 1 );
    }

    this.service.post(Card.payloadCard(this.payload), uri +
      StorageUtil.getUser().id + '/' + UriKey.LEADS_PAGADORES)
      .subscribe((r) => {

        StorageUtil.setResponse(r);
        this.goPage();

      }, () => {
        $('#erroBtn2').click();
      });
  }

  private cartao() {
    this.getToken().subscribe(id => {
      this.payload.card_token = id;
      this.payload.payment_method_id = this.typeCard;
      this.payload.nome = this.payload.nome + ' ' + this.payload.sobrenome;

      let uri = '';

      if (StorageUtil.getUser().documento.length == 14) {
        uri = UriKey.LEADS_PJ;
        dataLayerPushPayment('PJ', this.payload.tipo_pagamento, this.parcelas12, this.data.qtd_vidas);
      } else {
        dataLayerPushPayment('PF', this.payload.tipo_pagamento, this.valor_vida, 1 );
        uri = UriKey.LEADS;
      }

      this.service.post(Card.payloadCard(this.payload), uri +
        StorageUtil.getUser().id + '/' + UriKey.LEADS_PAGADORES)
        .subscribe((r) => {

          StorageUtil.setResponse(r);
          this.goPage();

        }, () => {
          $('#erroBtn').click();
        });
    });
  }

  public setTipoPagamento(event): void {
    this.payload.tipo_pagamento = event;
  }

  public setParcela(event) {
    this.card.parcelas = event.target.value;
  }

  public validateDoc(): void {
    if (!ValidateUtil.validarCPF(this.pagador[0].documento)) {
      throw EvalError(MsgUtil.msgErrorToast('Documento inválido'));
    }
  }

  public validCard(event) {

    event.target.value = event.target.value.replace(/\D+/g, '');
    const card = cardValidator(event.target.value);
    if (card) {
      const r = Card.getTypeCardId(event.target.value);

      if (r) {
        const x = this.cardValid.find((option) => option.id == r);
        this.imgCard = x.secure_thumbnail;
        this.typeCard = x.id;
      }
    } else {
      MsgUtil.msgErrorToast('Cartão inválido');
      this.imgCard = '';
      this.typeCard = '';
    }

    if (event.target.value.length < 16) {
      this.imgCard = '';
      this.typeCard = '';
    }
  }

  private getToken() {
    const date = FormateDate.parse('01/' + this.card.vencimento_cartao).replace(/\D+/g, '');

    return this.service.mercadoPagoGerarToken(Card.payloadToken(this.card, date, this.payload.documento));
  }

  private invalidPayload() {
    const msg = 'Todos os campos são obrigatórios';

    this.pagador.forEach((element) => {

      if (!this.readonly) {
        if (element.celular == '' || element.celular == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.documento == '' || element.documento == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.email == '' || element.email == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.sobrenome == '' || element.sobrenome == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.nome == '' || element.nome == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        this.payload.celular = this.payload.celular.replace(/\D+/g, '');
        this.payload.documento = this.payload.documento.replace(/\D+/g, '');
      }
    });

    if (this.payload.tipo_pagamento != 'BOLETO') {
      if (this.card.codgo_seguranca_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.nome_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.numero_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.parcelas == '' && this.payload.tipo_pagamento == 'CREDITO') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.vencimento_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }
      this.payload.parcelas = this.card.parcelas;
    }
  }

  private loadpayment_methods() {
    this.service.loadpayment_methods()
      .subscribe((r) => {
        this.cardValid = r.filter((option) => option.status == 'active');
      });
  }

  private loadDateExpiration() {
    const today = new Date();
    const date = today.setDate(today.getDate() + 3);
    this.dataVencimento = FormateDate.formateData(date);
  }

  private goPage() {
    if (this.payload.tipo_pagamento == 'BOLETO') {
      this.router.navigate(['/boleto']);
    } else {
      this.router.navigate(['/sucesso']);
    }
  }

  private loadPj() {

    if (this.data.documento.length == 14) {
      this.pf = false;
      const user = StorageUtil.getUser();
      this.pagador.forEach((element) => {
        element.celular = user.celular;
        element.documento = user.documento;
        element.email = user.email;
        element.nome = user.nome;
      });
      this.readonly = true;

    } else {
      this.pf = true;
    }
  }

  ngOnInit() {
    this.loadPj();
    this.loadDateExpiration();
    this.loadpayment_methods();

    this.preco_mes = this.preco_mes.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    this.parcelas12 = this.parcelas12.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
  }
}
