import { Component, OnInit } from '@angular/core';
import { StorageUtil } from 'src/app/utils/storage';

@Component({
  selector: 'app-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: ['./boleto.component.css']
})
export class BoletoComponent implements OnInit {

  boleto = StorageUtil.getPaymentResponse();
  constructor() { }

  ngOnInit() {
  }

}
