import { Component, OnInit } from '@angular/core';
import { PaymentRequest } from '../request';
import { StorageUtil } from 'src/app/utils/storage';
import { FormateDate } from 'src/app/utils/formateDate';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { UriKey } from 'src/app/utils/uri-service.service';
import { Card } from 'src/app/utils/card';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.css']
})

export class PagamentoComponent implements OnInit {

  payload = Card.loadpayloadCard();
  card = Card.inputCard();

  data = StorageUtil.getUser();

  pf;
  anual = false;
  valor_vida = 24.9;
  preco_mes: any = this.data.qtd_vidas * this.valor_vida;
  parcelas12: any = (this.data.qtd_vidas * this.valor_vida) * 6;

  constructor() {}

  private loadPj() {

    if (this.data.documento.length == 14) {
      this.pf = false;
      this.anual = true;
    } else {
      this.pf = true;
    }
  }


  ngOnInit() {
    this.loadPj();
    this.preco_mes = this.preco_mes.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    this.parcelas12 = this.parcelas12.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    console.log(this.data.qtd_vidas);
    console.log(this.data);
  }
}
