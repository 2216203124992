import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConstantPool } from '@angular/compiler';
import { StorageUtil } from '../utils/storage';

declare var dataLayerPushType;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) {

  }
  ngOnInit() {
    StorageUtil.clearSession();
    dataLayerPushType();
  }
}
