import { UserCallback } from './../models/user-callback';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageUtil } from './../utils/storage';
import { RegisterCustomer } from '../models/register-customer';
import { Headers } from '../models/headers';
import { SocialPayload } from '../models/social-payload';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  registerCustomer(registerCustomer: RegisterCustomer) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    console.log(requestOptions);
    return this.http.post(`${environment.urlBase}open/register-customer/`, registerCustomer, requestOptions);
  }

  getUserFull(id: string) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}open/customer-data/${id}`, requestOptions);
  }

  checkUniqueUser(userName: string) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };

    return this.http.get(`${environment.urlBase}open/system-user/validate-name/${userName}`, requestOptions);
  }

  socialLogin(data: SocialPayload): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}social/login`, data, requestOptions);
  }

  updateCustomer(idCustomer: string, data, token) {
    this.headers.Authorization = 'Bearer ' + token;
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.patch(`${environment.urlBase}customers/` + idCustomer, data, requestOptions);
  }

  

}
