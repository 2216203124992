import { PagamentoRegraComponent } from './home/pagamento-modulo/pagamento-regra/pagamento-regra.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CodigoComponent } from './home/codigo/codigo.component';
import { FinalComponent } from './home/final/final.component';
import { BoletoComponent } from './home/boleto/boleto.component';
import { PagamentoSucessoComponent } from './home/pagamento-sucesso/pagamento-sucesso.component';
import { PagamentoComponent } from './home/pagamento/pagamento.component';
import { CadastroPfComponent } from './home/cadastro-pf/cadastro-pf.component';
import { CadastroPjComponent } from './home/cadastro-pj/cadastro-pj.component';
import { PlanosComponent } from './home/planos/planos.component';
import { DadosCadastraisComponent } from './home/dados-cadastrais/dados-cadastrais.component';
import { TempPagamentoComponent } from './home/temp-pagamento/temp-pagamento.component';
import { SucessoComponent } from './home/sucesso/sucesso.component';
import { SucessoExternoComponent } from './home/sucesso-externo/sucesso-externo.component';
import { FacebookReturnComponent } from './home/cadastro-pf/facebook-return/facebook-return.component';
import { GoogleReturnComponent } from './home/cadastro-pf/google-return/google-return.component';

const routes: Routes = [
  //{path: '', component:  HomeComponent}, // Pagina de escolha PF e PJ
  {path: '', component:  CadastroPfComponent},
  {path: 'cadastro-pf', component: CadastroPfComponent},
  {path: 'cadastro-pj', component: CadastroPjComponent},

  {path: 'codigo', component: CodigoComponent},
  {path: 'planos', component: PlanosComponent},
  {path: 'dados-cadastrais', component: DadosCadastraisComponent},
  {path: 'pagamento', component: PagamentoComponent},
  //{path: 'pag', component: TempPagamentoComponent},
  {path: 'final', component: PagamentoSucessoComponent},

  {path: 'sucesso', component: PagamentoSucessoComponent},    
  {path: 'boleto', component: BoletoComponent},
  {path: 'sucesso-externo', component: SucessoExternoComponent},  
  {path: 'step-sucesso', component: SucessoComponent},

  /* Facebook Login */
  {path: 'returnfb', component: FacebookReturnComponent},

  /* Google Login */
  {path: 'returngoogle', component: GoogleReturnComponent},

  {path: 'pag', component: PagamentoRegraComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
