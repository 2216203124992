import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from './utils/http-util';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './service/api.service';
import { MessageService } from 'primeng/api';
import { UriKey } from './utils/uri-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StorageUtil } from './utils/storage';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {

  id;
  subscription: Subscription;
  queryParamSubscription: Subscription;

  constructor(private http: HttpClient,
              private httputil: HttpUtil,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public msgService: MessageService,
              public apiService: ApiService) {

     }

  public generateGantCode(): Observable<any> {
    const request = { client_id: environment.client_id, redirect_uri: 'http://localhost' };
    return this.http.post<any>(this.httputil.buildUrlOauth(UriKey.GRANT_CODE), request, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public generateAccessToken(code: any): Observable<any> {
    const request = { grant_type: 'authorization_code', code };
    return this.http.post<any>(this.httputil.buildUrlOauth(UriKey.ACCES_TOKEN), request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(environment.client_id + ':' + environment.passWord)
      }
    });
  }

  public refresh(code: any): Observable<any> {
    const request = { grant_type: 'refresh_token', refresh_token: code };
    return this.http.post<any>(this.httputil.buildUrlOauth(UriKey.ACCES_TOKEN), request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(environment.client_id + ':' + environment.passWord)
      }
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }

}
