export class PaymentRequest {

  public static payloadRegister(data, enderec): any {
    return {
      nome: data.nome + ' ' + data.sobrenome,
      documento: data.documento.replace(/\D+/g, ''),
      data_nascimento: data.data_nascimento,
      sexo: data.sexo,
      celular: data.celular.replace(/\D+/g, ''),
      email: data.email,
      cep: enderec.cep.replace(/\D+/g, ''),
      logradouro: enderec.logradouro,
      numero_endereco: data.numero_endereco,
      complemento: data.complemento,
      bairro: enderec.bairro,
      cidade: enderec.localidade,
      uf: enderec.uf
    };
  }

  public static payloadRegisterPj(data, enderec): any {
    return {
      plano_id: '103',
      qtd_vidas: data.qtd_vidas,
      nome: data.nome,
      documento: data.documento.replace(/\D+/g, ''),
      nome_responsavel_financeiro: data.nome_responsavel_financeiro + ' ' + data.sobrenome,
      documento_responsavel_financeiro: data.documento_responsavel_financeiro.replace(/\D+/g, ''),
      email: data.email,
      celular: data.celular.replace(/\D+/g, ''),
      cep: enderec.cep.replace(/\D+/g, ''),
      qtdmeses: 6,
      valor: 2490,
      logradouro: enderec.logradouro,
      numero_endereco: data.numero_endereco,
      complemento: data.complemento,
      bairro: enderec.bairro,
      cidade: enderec.localidade,
      uf: enderec.uf
    };
  }

  public static payloadRegisterPagador(data) {
    return {
      nome: data.nome,
      documento: data.documento.replace(/\D+/g, ''),
      celular: data.celular.replace(/\D+/g, ''),
      email: data.email
    };
  }
}
