import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FacebookTokenCallback } from 'src/app/models/facebook-token-callback';
import { FacebookUserdataCallback } from 'src/app/models/facebook-userdata-callback';
import { SocialPayload } from 'src/app/models/social-payload';
import { OAuthService } from 'src/app/o-auth.service';
import { FacebookService } from 'src/app/service/social/facebook.service';
import { UserService } from 'src/app/service/user.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { StepsUtil } from 'src/app/utils/steps';
import { StorageUtil } from 'src/app/utils/storage';
import { Spinner } from 'src/app/utils/stpiner';

@Component({
  selector: 'app-facebook-return',
  templateUrl: './facebook-return.component.html',
  styleUrls: ['./facebook-return.component.css']
})
export class FacebookReturnComponent implements OnInit {

  public subs: Subscription;

  constructor(
    private facebookService: FacebookService,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private oAuthService: OAuthService
  ) {}

  ngOnInit() {
    this.subs = this.route.queryParams.subscribe(params => {
      if (params.code == null) {
        this.router.navigate(['/login']);
      } else {
        this.getAccessToken(params.code);
      }
    });
  }

  getAccessToken(code: string) {
    Spinner.spinner(document, true);
    this.facebookService.getAccessToken(code).subscribe((r: FacebookTokenCallback) => {
      if (r.accessToken) {
        this.getUserData(r.accessToken);
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Facebook. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

  getUserData(accessToken: string) {
    Spinner.spinner(document, true);
    this.facebookService.getUserData(accessToken).subscribe((r: FacebookUserdataCallback) => {
      if (r) {
        this.socialLogin(accessToken, r);
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Facebook. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

  socialLogin(accessToken: string, user: FacebookUserdataCallback) {
    Spinner.spinner(document, true)
    const payload: SocialPayload = {
      email: user.email,
      id: user.id,
      name: user.name,
      provider: 'FACEBOOK',
      token: accessToken
    };

    this.userService.socialLogin(payload).subscribe((r) => {
      if(r.jwtToken) {
        StepsUtil.insertSteps('stepSocial', { jwtToken: r.jwtToken, provider: 'FACEBOOK'});
        this.router.navigate(['/planos']);
      } else {
        this.router.navigate(['/cadastro-pf']);
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Facebook. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );

  }

}
