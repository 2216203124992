import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SocialPayload } from 'src/app/models/social-payload';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { StepsUtil } from 'src/app/utils/steps';
import { Spinner } from 'src/app/utils/stpiner';
import { UserService } from '../user.service';
import { environment } from './../../../environments/environment';

declare var AppleID;

@Injectable({
  providedIn: 'root'
})
export class AppleService {

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private userService: UserService,
  ) { }

  loadApple() {
    return new Promise((resolve, reject) => {
      AppleID.auth.init({
        clientId: environment.apple_client_id,
        scope: 'name email',
        redirectURI: environment.apple_redirect_uri,
        state: '',
        nonce: '',
        usePopup: true //or false defaults to false
      });
    });
  }

  responseApple(event) {
    let appleResponse = JSON.parse(event.data).data;
    if (appleResponse.authorization) {
      //The user is logged in
      let payload: SocialPayload = { token: appleResponse.authorization.id_token, provider: 'APPLE' };
      if (appleResponse.user) {
        payload.name = appleResponse.user.name.firstName + " " + appleResponse.user.name.lastName;
        payload.email = appleResponse.user.email;
      }

      return payload;
    }
    return false;
  }
  
}
