declare var $;

export class Util {

   public static dataTable() {
    $(document).ready(function() {
        $('#example').DataTable();
    } );
   }

   public static cepResponse() {
    return {
      cep: '',
      logradouro: '',
      complemento: '',
      bairro: '',
      localidade: '',
      uf: '',
      unidade: '',
      ibge: '',
      gia: ''
    };
}


   public static captalize( value ) {
    const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

    return capitalize(value);
   }
}
