import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepsUtil } from 'src/app/utils/steps';
import { environment } from './../../../environments/environment';


@Component({
  selector: 'app-sucesso',
  templateUrl: './sucesso.component.html',
  styleUrls: ['./sucesso.component.css']
})
export class SucessoComponent implements OnInit {

  email: string;

  constructor( private router: Router) { }

  ngOnInit() {
    this.email = StepsUtil.getSteps().stepEmail.email;
  }

  goLogin() {
    window.location.href = environment.url + 'login';
  }

}
