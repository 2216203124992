import { environment } from 'src/environments/environment';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { OAuthService } from './o-auth.service';
import { MsgUtil } from './utils/msgUtil';
import { Router } from '@angular/router';
import { StorageUtil } from './utils/storage';

declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends OAuthService implements OnInit {

  start =  StorageUtil.getUser() ? false : true;
  oAuth;
  refresh$ = new EventEmitter<any>();

  public loadOAuth(): void {
    this.start = false;
    
      this.generateGantCode()
      .subscribe((response: any) => {

        const index = response.redirect_uri.indexOf('=');
        const token = response.redirect_uri.substring(index + 1, response.redirect_uri.length);

        this.generateAccessToken(token)
          .subscribe((r: any) => {

            this.start = true;
            this.oAuth = r;
            this.apiService.token = this.oAuth.access_token;
            StorageUtil.setAccessToken(this.oAuth.access_token);

            setTimeout(() => {
              this.refresh$.emit();
            }, 60000);
          }, err => {
          });
      });
  }

  private refreshToken() {
    const time = Number(this.oAuth.expires_in.toString() + '000') - 360000;
    setInterval(() => {
      this.refresh(this.oAuth.refresh_token).subscribe((r: any) => {
        this.oAuth = r;
        this.apiService.token = this.oAuth.access_token;
      });
    }, time);
  }

  ngOnInit() {
    this.loadOAuth();
    this.addSingle();
    this.clear();
    this.refresh$.subscribe(() => {
      this.refreshToken();
    });
  }


  addSingle() {
    MsgUtil.addSingle$.subscribe((result) => {
      this.msgService.add(result);
    });
  }

  clear() {
    MsgUtil.clear$.subscribe(() => {
      this.msgService.clear();
    });
  }

  loadAncora() {
    setTimeout(() => {
      $('html, body').animate(
        { scrollTop: $('#top').position().top },
        'slow'
      );
    }, 300);
  }
}
