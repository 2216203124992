import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  url = environment.url;
  constructor(private router: Router) { }

  goUp(value) {
    if (window.location.href.indexOf(value) > -1) {
      setTimeout(() => {
        $('html, body').animate(
          { scrollTop: $('#top').position().top },
          'slow'
        );
      }, 300);
    }
  }

  ngOnInit() {
  }
}
