import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpUtil {

  constructor() { }

  public static HttpParamsAndPath(param?, path?) {
    const httpAtributs = new HttpAtributs();

    if (param) {
      httpAtributs.params = param;
    } else {
      httpAtributs.params = {};
    }

    if (path) {
      httpAtributs.path = path;
    } else {
      httpAtributs.path = '';
    }

    return httpAtributs;
  }

  public buildUrl(key: any): any {
    return environment.urlBase + key;
  }

  public header(token): any {
    return {
      client_id: environment.client_id,
      access_token: token,
      'Content-Type': 'application/json',
    };
  }

  public headerRecuperarSenha(token, login): any {
    return {
      client_id: environment.client_id,
      access_token: token,
      'Content-Type': 'application/json',
      login
    };
  }

  public buildSMS(key: any): any {
    return environment.sms + key;
  }

  /* Header gateway */

  public buildUrlOauth(key: any): any {
    return environment.urlBaseOauth + key;
  }

  public headerOauth(): any {
    return {
      client_id: environment.client_id,
      access_token: '',
      'Content-Type': 'application/json'
    };
  }
}

export class HttpAtributs {
  params: any = {};
  path: any = '';
}
