import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { StorageUtil } from 'src/app/utils/storage';

@Component({
  selector: 'app-pagamento-sucesso',
  templateUrl: './pagamento-sucesso.component.html',
  styleUrls: ['./pagamento-sucesso.component.css']
})
export class PagamentoSucessoComponent implements OnInit {

  user = StorageUtil.getUser();
  boleto;
  subscription: Subscription;


  constructor(private api: ApiService) { }

  ngOnInit() {
    this.subscription = this.api.boleto.subscribe( boleto => this.boleto = boleto);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
