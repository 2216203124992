import { StorageUtil } from './storage';
import { Steps } from 'src/app/models/steps';

export class StepsUtil {

    public static getSteps(): Steps {
        return StorageUtil.getRegisterSteps();
    }

    public static hasSteps() {
        return this.getSteps() ? true : false;
    }

    public static insertSteps(key, data) {
       let steps;
       !this.hasSteps() ? steps = {} : steps = this.getSteps();
       steps[key] = data;
       StorageUtil.setRegisterSteps(steps);
    }
}
