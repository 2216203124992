import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpUtil, HttpAtributs } from '../utils/http-util';
import { Spinner } from '../utils/stpiner';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MsgUtil } from '../utils/msgUtil';
import { UriKey } from '../utils/uri-service.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token;
  boleto: BehaviorSubject<string>;

  constructor(private http: HttpClient, private httputil: HttpUtil) { }

  public post(request: any, key?, httpAtributs?: HttpAtributs): Observable<any> {
    httpAtributs = this.validateHttpAtributsBlank(httpAtributs);

    Spinner.spinner(document, true);
    return this.http.post<any>(`${this.httputil.buildUrl(key)}${httpAtributs.path}`, request, {
      headers: this.httputil.header(this.token),
      params: httpAtributs.params
    }).pipe(map((response: any) => {
      Spinner.spinner(document);
      return response;
    }));
  }

  public put(request: any, key?, httpAtributs?: HttpAtributs): Observable<any> {

    httpAtributs = this.validateHttpAtributsBlank(httpAtributs);

    Spinner.spinner(document, true);
    return this.http.put<any>(`${this.httputil.buildUrl(key)}${httpAtributs.path}`, request, {
      headers: this.httputil.header(this.token),
      params: httpAtributs.params
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      return response;
    }));
  }

  public get(key?, httpAtributs?: HttpAtributs): Observable<any> {

    httpAtributs = this.validateHttpAtributsBlank(httpAtributs);

    Spinner.spinner(document, true);

    return this.http.get<any>(`${this.httputil.buildUrl(key)}${httpAtributs.path}`, {
      headers: this.httputil.header(this.token),
      params: httpAtributs.params
    }).pipe(map((response: any) => {
      Spinner.spinner(document);

      return response;
    }));
  }

  public getById(id: any, key?, httpAtributs?: HttpAtributs): Observable<any> {

    httpAtributs = this.validateHttpAtributsBlank(httpAtributs);

    Spinner.spinner(document, true);

    return this.http.get<any>(`${this.httputil.buildUrl(key)}${httpAtributs.path}${id}`, {
      headers: this.httputil.header(this.token),
      params: httpAtributs.params
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      return response;
    }));
  }

  public getLead(id: any): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(`${this.httputil.buildUrl(UriKey.LEADS)}${id}`, {
      headers: this.httputil.header(this.token)
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      return response;
    }));
  }

  public getCep(cep): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(`${environment.cep}${cep}`, {
      headers: this.httputil.header(this.token)
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      if (response.erro) {
        MsgUtil.msgErrorToast('CEP não encontrado');
      }

      return response;
    }));
  }

  public mercadoPagoGerarToken(request) {

    return this.http.post<any>(`${environment.urlMP}${UriKey.MP_CARD_TOKEN}`, request, {
      params: {
        public_key: environment.public_key
      }
    }).pipe(map((response: any) => {
      return response.id;
    }));
  }

  public loadpayment_methods() {

    return this.http.get<any>(`${environment.urlMP}${UriKey.MP_PAYMENT_METHOD}`, {
      params: {
        public_key: environment.public_key
      }
    });
  }

  public validateHttpAtributsBlank(httpAtributs: HttpAtributs) {

    if (httpAtributs == undefined) {
      return new HttpAtributs();
    }

    return httpAtributs;
  }

  public sendSMS(key?, httpAtributs?: HttpAtributs): Observable<any> {

    httpAtributs = this.validateHttpAtributsBlank(httpAtributs);

    Spinner.spinner(document, true);

    return this.http.post<any>(`${this.httputil.buildSMS(key)}${httpAtributs.path}`, null, {
      headers: this.httputil.header(this.token),
      params: httpAtributs.params
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      return response;
    }));
  }

  public iuguGerarToken( request ) {
    return this.http.post<any>(`${environment.url_iugu_token}` , request, {
    }).pipe(map((response: any) => {
      return response.id;
    }));
  }

  public boletoSuccess() {
    this.boleto = new BehaviorSubject('boleto');
  }

}
