import { HttpUtil } from './../../utils/http-util';
import { Component, OnInit } from '@angular/core';
import { StorageUtil } from 'src/app/utils/storage';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { UriKey } from 'src/app/utils/uri-service.service';
import { MsgUtil } from 'src/app/utils/msgUtil';

const STYLE_SUCESS = 'form-control required';
const STYLE_ERROR = 'is-invalid form-control required';

declare var $;

@Component({
  selector: 'app-codigo',
  templateUrl: './codigo.component.html',
  styleUrls: ['./codigo.component.css']
})
export class CodigoComponent implements OnInit {

  codeStyle = STYLE_SUCESS;
  phone;
  code;
  msgErro;
  btnContinuar = true;
  registred =  StorageUtil.getUser();

  constructor(
    private service: ApiService,
    private router: Router) { }

  private buildPhone() {
    if (this.registred) {
      this.phone = '(' + this.registred.celular.toString().substring(0, 2) + ') '
        + this.registred.celular.toString().substring(2, 7) + '-'
        + this.registred.celular.toString().substring(7, this.registred.celular.toString().length);
    }
  }

  public validateCod() {
    this.codeStyle = STYLE_SUCESS;
    this.code = this.code.replace(/\D+/g, '');
    this.code.length == 4 ?
      this.btnContinuar = false : this.btnContinuar = true;
  }

  public sendCode() {
    this.msgErro = 'Código inválido ou expirado';
    let uri = '';

    if (StorageUtil.getUser().documento.length == 14) {
      uri = UriKey.LEADS_PJ;
    } else {
      uri = UriKey.LEADS;
    }

    this.service.get(uri +
      StorageUtil.getUser().id + '/' +
      UriKey.LEADS_CODE +
      this.code).subscribe(() => {
        this.router.navigate(['/pagamento']);
      }, () => {
        this.codeStyle = STYLE_ERROR;
      });
  }


  public reenviar() {


    let uri = '';

    if (StorageUtil.getUser().documento.length == 14) {
      uri = UriKey.RSEND_CODE_PJ;
    } else {
      uri = UriKey.RSEND_CODE;
    }

    const param = HttpUtil.HttpParamsAndPath({}, StorageUtil.getUser().id + '/' + UriKey.LEADS_CODE);
    this.service.sendSMS(uri, param).subscribe(() => {
      MsgUtil.msgSuccessToast('Reenviado com sucesso');
    });
  }

  ngOnInit() {
    this.buildPhone();
  }

}
