import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompanySubscriptionPurchase } from '../models/companySubscription-purchase';
import { Headers } from '../models/headers';
import { StorageUtil } from '../utils/storage';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  setPlanToCompany(data: CompanySubscriptionPurchase) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}open/purchase-company-plan`,data, requestOptions);
  }

  setPlanToCompanyPV(id: string, data: CompanySubscriptionPurchase) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}open/${id}/finish-company-purchase`,data, requestOptions);
  }

  getSubscriptionById(subscriptionId) {
    console.log(this.headers)
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}company-subscriptions/` + subscriptionId, requestOptions);
  }
}
