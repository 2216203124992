import { PagamentoRetorno } from './../../../models/pagamentoRetorno';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-pagamento-componente',
  templateUrl: './pagamento-componente.component.html',
  styleUrls: ['./pagamento-componente.component.css']
})
export class PagamentoComponenteComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  //Inputs
  @Input() cartaoQuantidadeParcelas: number;
  @Input() cartaoValor: number;
  @Input() boletoValor: number;
  @Input() boletoVencimento: string;
  
  //Outputs
  pagamentoSelecionado;
  cartaoForm: FormGroup;
  cartaoFormSubmitted = false;
  @Output() pagamentoDisparado = new EventEmitter<object>();

  //Lógicas
  parcelas: {parcela: number, valor: number}[];  

  ngOnInit() {
    if(this.cartaoValor) {
      this.pagamentoSelecionado = 'CARTAO';
      this.gerarParcelas();
      this.gerarFormularioCartao();
      
    } else if(this.boletoValor) {
      this.pagamentoSelecionado = 'BOLETO';
    }
  }

  gerarParcelas() {
    this.parcelas = [];
    for (let index = 1; index < this.cartaoQuantidadeParcelas + 1; index++) {
      this.parcelas.push({parcela: index, valor: (this.cartaoValor/index)});      
    }
  }

  gerarFormularioCartao() {
    this.cartaoForm = this.formBuilder.group({
      parcela: ['', Validators.required],
      number: ['', Validators.required],
      name: ['', Validators.required],
      expiry: ['', Validators.required],
      cvc: ['', Validators.required]
    });
    this.cartaoForm.patchValue({ parcela: "1" });
  }

  setTipoPagamento(pagamento) {
    this.pagamentoSelecionado = pagamento;
  }

  efetuarPagamento() {
    if (this.pagamentoSelecionado === 'CARTAO') {
      this.cartaoFormSubmitted = true;
      // stop here if form is invalid
      if (this.cartaoForm.invalid) {
        return;
      }
    }
    let pagamentoRetorno: PagamentoRetorno = { pagamentoSelecionado: this.pagamentoSelecionado };
    if(this.pagamentoSelecionado === 'CARTAO') {
      Object.assign(pagamentoRetorno, this.cartaoForm.value);
    }
    this.pagamentoDisparado.emit(pagamentoRetorno);
  }

}
