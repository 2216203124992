import { StorageUtil } from 'src/app/utils/storage';
import { NgZone } from '@angular/core';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { UriKey } from 'src/app/utils/uri-service.service';
import { Card } from 'src/app/utils/card';
import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormateDate } from 'src/app/utils/formateDate';

declare var cardValidator;
declare var dataLayerPushPayment;
declare var $;
declare var Iugu;
const valorVIda = 49.9;

@Component({
  selector: 'app-pagamento-mensal',
  templateUrl: './pagamento-mensal.component.html',
  styleUrls: ['./pagamento-mensal.component.css'],
})
export class PagamentoMensalComponent implements OnInit {
  payload = Card.loadpayloadCard();
  card = Card.inputCard();

  data = StorageUtil.getUser();
  pagador = [this.payload];
  readonly = false;
  btnFinalizar = 'CARD';
  dataVencimento;
  cardValid;
  imgCard: string;
  typeCard: any = '';
  pf;
  paymentEvent$ = new EventEmitter<any>();
  paymentEventErro$ = new EventEmitter<any>();
  Subscription = new Subscription();

  valor_vida = 29.9;

  constructor(private service: ApiService,
              private router: Router,
              private ngZone: NgZone) {}

  public onReadOnly() {
    if (this.readonly) {
      this.readonly = false;
      this.pagador.forEach((element) => {
        element.celular = '';
        element.documento = '';
        element.email = '';
        element.nome = '';
        element.sobrenome = '';
      });
      $('#nomeCartao').val('');
    } else {
      this.readonly = true;
      const user = StorageUtil.getUser();

      this.pagador.forEach((element) => {
        element.celular = user.celular;
        element.documento = user.documento;
        element.email = user.email;
        element.nome = user.nome;
      });

      this.card.nome_cartao = user.nome;
      $('#nomeCartao').val(user.nome);
    }
  }

  public tipoPagamento(value) {
    this.btnFinalizar = value;
  }

  public registrarPagador() {

    this.invalidPayload();

    if (this.payload.tipo_pagamento == 'BOLETO') {
      this.boleto();
    } else {
      this.pagamentoMensal();
    }
  }

  private pagamentoMensal() {
    this.generateTokenIugu();
  }

  public setTipoPagamento(event): void {
    this.payload.tipo_pagamento = event;
  }

  public validateDoc(): void {
    if (!ValidateUtil.validarCPF(this.pagador[0].documento)) {
      throw EvalError(MsgUtil.msgErrorToast('Documento inválido'));
    }
  }

  public validCard(event) {
    event.target.value = event.target.value.replace(/\D+/g, '');
    const card = cardValidator(event.target.value);
    if (card) {
      const r = Card.getTypeCardId(event.target.value);

      if (r) {
        const x = this.cardValid.find((option) => option.id == r);
        this.imgCard = x.secure_thumbnail;
        this.typeCard = x.id;
      }
    } else {
      MsgUtil.msgErrorToast('Cartão inválido');
      this.imgCard = '';
      this.typeCard = '';
    }

    if (event.target.value.length < 16) {
      this.imgCard = '';
      this.typeCard = '';
    }
  }

  public generateTokenIugu() {
    $('#submitPagamentoMensal').click();
  }

  private finalizarPagamento(token) {

    this.payload.card_token = token;
    this.payload.payment_method_id = this.typeCard;
    this.payload.nome = this.payload.nome + ' ' + this.payload.sobrenome;

    const uri = this.setTagGTM();

    const sub = this.service
      .post(
        Card.payloadCard(this.payload),
        `${uri}${StorageUtil.getUser().id}/${UriKey.LEADS_PAGADORES}`).subscribe((r) => {

          StorageUtil.setResponse(r);

          this.ngZone.run(() => this.router.navigate(['/sucesso']));

        }, () => { $('#erroBtn').click(); });

    this.Subscription.add(sub);
  }

  private invalidPayload() {
    const msg = 'Todos os campos são obrigatórios';
    this.setValueCard();

    this.pagador.forEach((element) => {
      if (!this.readonly) {
        if (element.celular == '' || element.celular == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.documento == '' || element.documento == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.email == '' || element.email == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.sobrenome == '' || element.sobrenome == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        if (element.nome == '' || element.nome == undefined) {
          throw EvalError(MsgUtil.msgErrorToast(msg));
        }

        this.payload.celular = this.payload.celular.replace(/\D+/g, '');
        this.payload.documento = this.payload.documento.replace(/\D+/g, '');
      }
    });

    if (this.payload.tipo_pagamento != 'BOLETO') {
      if (this.card.codgo_seguranca_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.nome_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.numero_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      if (this.card.vencimento_cartao == '') {
        throw EvalError(MsgUtil.msgErrorToast(msg));
      }

      this.payload.parcelas = this.card.parcelas;
    }

  }

  private boleto() {
    let uri = '';
    this.payload.parcelas = '0';
    uri = UriKey.LEADS;
    dataLayerPushPayment('PF', 'Boleto', this.valor_vida, 1 );
    this.service.boletoSuccess();

    this.service.post(Card.payloadCard(this.payload), uri +
      StorageUtil.getUser().id + '/' + UriKey.LEADS_PAGADORES)
      .subscribe((r) => {
        // console.log(r)
        StorageUtil.setResponse(r);
        this.ngZone.run(() => this.router.navigate(['/sucesso']));

      }, (err) => {
        console.log(err);
      });
  }

  private loadpayment_methods() {
    this.service.loadpayment_methods().subscribe((r) => {
      this.cardValid = r.filter((option) => option.status == 'active');
    });
  }

  private loadPj() {
    if (this.data.documento.length == 14) {
      this.pf = false;
      const user = StorageUtil.getUser();
      this.pagador.forEach((element) => {
        element.celular = user.celular;
        element.documento = user.documento;
        element.email = user.email;
        element.nome = user.nome;
      });
      this.readonly = true;
    } else {
      this.pf = true;
    }
  }

  private setTagGTM() {
    if (StorageUtil.getUser().documento.length == 14) {
      return UriKey.LEADS_PJ;
    } else {
      dataLayerPushPayment(
        'PF',
        this.payload.tipo_pagamento,
        49,
        90,
        valorVIda
      );
      return UriKey.LEADS;
    }
  }

  private loadFinalizarPagamentoMensal() {
    this.paymentEvent$.subscribe((token) => {
      this.finalizarPagamento(token);
    });
  }

  private loadFinalizarPagamentoMensalError() {
    this.paymentEventErro$.subscribe((error) => {
      this.mapErroIugu(error);
    });
  }

  private mapErroIugu(erro: string) {
    if (erro.indexOf('last_name') > -1) {
      return MsgUtil.msgErrorToast('Sobrenome é obrigatório');
    }
    if (erro.indexOf('first_name') > -1) {
      return MsgUtil.msgErrorToast('Verifique se há espaços antes do nome');
    }
    if (erro.indexOf('number') > -1) {
      return MsgUtil.msgErrorToast('Cartão inválido');
    }
    if (erro.indexOf('expiration') > -1) {
      return MsgUtil.msgErrorToast('Data inválida');
    }
    if (erro.indexOf('verification_value') > -1) {
      return MsgUtil.msgErrorToast('CVV inválido');
    }
  }

  private setValueCard() {
    this.card.nome_cartao = $('#nomeCartao').val();
    this.card.codgo_seguranca_cartao = $('#segCode').val();
    this.card.numero_cartao = $('#numeroCartao').val();
    this.card.parcelas = '0';
    this.card.vencimento_cartao = $('#expiration').val();
  }

  private loadPaymentForm() {
    const paymentEvent$ = this.paymentEvent$;
    let token = '';

    Iugu.setAccountID(environment.iugu_account_Id);

    if (!environment.production) {
      Iugu.setTestMode(true);
    }

    const paymentEventErro$ = this.paymentEventErro$;

    $('#payment-form').submit(function(evt) {
      const tokenResponseHandler = function(data) {
        if (data.errors) {
          paymentEventErro$.emit(JSON.stringify(data.errors));
          return;
        }

        token = data.id;
        paymentEvent$.emit(token);
      };
      Iugu.createPaymentToken(this, tokenResponseHandler);
    });
  }

  private loadDateExpiration() {
    const today = new Date();
    const date = today.setDate(today.getDate() + 3);
    this.dataVencimento = FormateDate.formateData(date);
  }

  ngOnInit() {
    this.loadPaymentForm();
    this.loadPj();
    this.loadpayment_methods();
    this.loadFinalizarPagamentoMensal();
    this.loadFinalizarPagamentoMensalError();
    this.loadDateExpiration();
  }

  ngOnDestroy() {
    this.Subscription.unsubscribe();
  }
}
