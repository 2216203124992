import { StorageUtil } from 'src/app/utils/storage';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sucesso-externo',
  templateUrl: './sucesso-externo.component.html',
  styleUrls: ['./sucesso-externo.component.css']
})
export class SucessoExternoComponent implements OnInit {

  tipoPessoa;
  tipoPagamento;
  email;
  social;
  bankSlipUrl;
  bankSlipBarCode;
  linkButton = this.sanitizer.bypassSecurityTrustUrl(environment.url + 'login');
  textButton = 'OK, entendi!';
  fromIos = false;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.route.queryParams.subscribe(params => {
      this.tipoPessoa = params['tipoPessoa'].toUpperCase();
      this.tipoPagamento = params['tipoPagamento'];
      this.email = params['email'];
      this.social = params['social'];
      this.bankSlipUrl = params['bankSlipUrl'];
      this.bankSlipBarCode = params['bankSlipBarCode'];
    });
    let deeplink = StorageUtil.getDeepLink();
    if (deeplink) {
      this.linkButton = this.sanitizer.bypassSecurityTrustUrl(deeplink);
      this.textButton = 'OK, entendi! Voltar para o aplicativo.';
    }
    let fromApp = StorageUtil.getFromApp();
    if (fromApp === 'ios') {
      this.linkButton = null;
      this.fromIos = true;
    }
  }

  ngOnInit() {
    console.log(this.tipoPessoa);
    console.log(this.tipoPagamento);
    console.log(this.email);
    console.log(this.social);
    console.log(this.bankSlipUrl);
    console.log(this.bankSlipBarCode);
  }

}
